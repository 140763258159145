<template>
  <drawer-form
    ref="drawerForm"
    :title="model.id>0?'编辑':'新增'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='项目' prop="projectId">
      <a-select v-model="model.projectId">
        <!--        <a-select-option value=""></a-select-option>-->
        <a-select-option v-for="project in projectList" :value="project.id">{{project.name}}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='分润金额' prop="money">
      <a-input v-model="model.money"/>
    </a-form-model-item>

    <a-form-model-item label='备注' prop="remark">
      <a-input v-model="model.remark"/>
    </a-form-model-item>

  </drawer-form>

</template>
<script>

  export default {
    data() {
      return {
        model: {},
        projectList:[],
        rules: {
          projectId: [
            {required: true, message: '不能为空'},
          ],
          money: [
            {required: true, message: '不能为空'},
          ],
          remark:[]
        },
        loading: false,

      }
    },


    methods: {
      show(model={}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.loading=false

        this.$refs.drawerForm.show()

        this.$get("web/profit/project/all").then(suc=>{
          this.projectList = suc.data
        })

      },

      hide() {
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        if (this.model.id > 0) {
          this.edit()
        } else {
          this.add()
        }
      },
      add() {
        this.loading = true
        let data = {
          ...this.model,
          money:this.model.money*100
        }
        this.$post('web/profit/record', data).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      edit() {
        this.loading = true
        this.$put('web/profit/record', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

    }
  }
</script>
